<template>
  <div>
    <br v-if="isMobile"><br v-if="isMobile">
      <b-row class="justify-content-center"> 
          <b-col cols="10" md="4" lg="10" style="margin-top: -57px;" >
            <center><img  src="elementos/movi.png" :class="isMobile ? 'rounded float-center' : 'rounded  float-right'" alt="Image" :style="isMobile ? 'width: 25%;' : 'width: 9%;'"></center>
          </b-col>
          <b-col cols="12" v-if="isMobile" ></b-col>
          <b-col cols="8" md="4" lg="10" style="margin-top: -72px;">
            <br v-if="isMobile"><br v-if="isMobile"><br v-if="isMobile">         
            <center><img src="elementos/logos.png" alt="Image" :style="isMobile ? ' margin-left: -40px; width: 135%;' : ' -590px'"   class=" float-left"></center> 
          </b-col>
      </b-row>
     <!-- <br><template v-if="tab == 2"><br><br><br><br><br><br><br><br></template> -->
    <center> 
      <b-row class="justify-content-center mt-5"  >
        <b-col cols="10" md="12">
          <br> <br v-if="!isMobile"> <br v-if="!isMobile">
          <!-- <h4 class="text-white" :style="isMobile ? 'font-size:18px;' : 'font-size:28px;'">Selecciona la información que desees visualizar y/o descargar</h4> -->
           <br v-if="isMobile"> 
           <img src="img/ganadores.png" class="rounded" alt="Image" :style="isMobile ? 'width:100%;' : ' margin-top: -10px;'" >
          <br>
        </b-col>
        <!-- <b-col cols="10" md="3" lg="2" class="mt-4">
              <v-btn
                class="ma-2" 
                :style="tab == 2 ? 'text-transform: none; background: #D66549; color: #fff;  border-radius: 10px; font-size:18px; width:80%;  height: 90%;' 
                : 'text-transform: none; background: #1975B8; color: #fff;  border-radius: 10px; font-size:18px; width:80%;  height: 90%;'"
                @click="ranki()"
              >
                Ranking
              </v-btn>
        </b-col>
        <b-col cols="10" md="3" lg="2" class="mt-4">
              <v-btn
                class="ma-2" 
                :style="tab == 1 ? 'text-transform: none; background: #D66549; color: #fff;  border-radius: 10px; font-size:18px; width:80%; height: 90%;' 
                : 'text-transform: none; background: #1975B8; color: #fff;  border-radius: 10px; font-size:18px; width:80%;  height: 90%;'" 
                @click="cambiarTab(1)"
              >
                Reportes
              </v-btn>
        </b-col> -->
      </b-row>
      <!-- <h4 style="color:#fff;" class="text-center mt-5">Descargar Reportes</h4> -->
<!--      
      <b-row class="justify-content-center">
        <b-col cols="10" md="6" lg="4">
          <br><br><br v-if="!isMobile"> 
        <center v-if="loader">
            <SpinnerLoader/>
            <p style="color:#000;">Procesando información, no cierres el navegador <br> espera un momento...</p>
        </center>  
        <br v-if="!isMobile"> 
         <b-row class="justify-content-center" v-if="tab == 1">
            <b-col cols="6" md="6" lg="6"> 
               <img src="elementos/bpropio1.png" :class="!isMobile ? 'rounded' :'rounded float-right'" alt="Image" :style="!isMobile ? 'width:80%; cursor: pointer;' : 'width:90%;'"  @click="descargaReporte()"> 
               <br>
               <b-spinner v-if="loader" small></b-spinner>
               <h5  class="text-white  mt-3"><b>Canal Propio</b></h5>
               <u class="mt-3" style="color:#000; cursor: pointer;" @click="descargaReporte()">Descargar <b-spinner v-if="loader" small></b-spinner></u>
            </b-col>
            <b-col cols="6" md="6" lg="6" :class="isMobile ? 'mt-1' : 'mt-2'"> 
               <img  src="elementos/besp1.png" :class="!isMobile ? 'rounded' :'rounded float-left'" alt="Image" :style="!isMobile ? 'width:80%; cursor: pointer;' : 'width:90%;'" @click="descargaReporte4()">
               <br>
               <b-spinner v-if="loader2" small></b-spinner>
               <h5  class="text-white  mt-3" ><b>Canal Especialista</b></h5>
               <u class="mt-3" style="color:#000; cursor: pointer;" @click="descargaReporte1()">Descargar /b-spinner></u>
            </b-col>
            <b-col cols="6" md="6" lg="6" class="mt-4"> 
               <img  src="elementos/bretail1.png" :class="!isMobile ? 'rounded' :'rounded float-right'" alt="Image" :style="!isMobile ? 'width:80%; cursor: pointer;' : 'width:90%;'" @click="descargaReporte1()">
               <br>
               <b-spinner v-if="loader2" small></b-spinner>
               <h5  class="text-white  mt-3" ><b>Canal Retail</b></h5>
               <u class="mt-3" style="color:#000; cursor: pointer;" @click="descargaReporte1()">Descargar <b-spinner v-if="loader2" small></b-spinner></u>
            </b-col>
            <b-col cols="6" md="6" lg="6" class="mt-4"> 
              <img src="elementos/btlmkt1.png" :class="!isMobile ? 'rounded' :'rounded float-left'" alt="Image" :style="!isMobile ? 'width:80%; cursor: pointer;' : 'width:90%; cursor: pointer;'"  @click="descargaReporte2()">
              <br>
              <b-spinner v-if="loader3" small></b-spinner>
              <h5  class="text-white  mt-3"><b>Canal TLMKT</b></h5>
              <u class="mt-5" style="color:#000; cursor: pointer;" @click="descargaReporte2()">Reporte </u>
            </b-col>
         </b-row> 
        </b-col>
      </b-row> -->
    </center>
    <br><br>
  </div>
</template>
<script>
// import Swal from 'sweetalert2'
// import SpinnerLoader from "../../utils/SpinnerLoader.vue";
export default {
  name: 'Embajador',
  //  components:{
  //   SpinnerLoader
  // },
  data(){
    return{
      isMobile: this.$mobile(),
      loader:false,
      loader2:false,
      loader3:false,
      tab:1,
      canal:0,
      arrayGan:[]
    }
  },
  computed: {
    user() {
      return this.$store.getters.getInfoUser;
    }
  },
  mounted(){
     this.$bus.$emit("cambia_embajador", true)
  },
  methods:{
    apibitacora(id){
      var seccion = "";
      switch (id) {
        case 1:
          seccion = 'Reporte ranking propio';
        break; 
        case 2:
          seccion = 'Reporte ranking retail';
        break;
        case 3:
          seccion = 'Reporte ranking TLMKT';
        break;
        case 4:
          seccion = 'Reporte ranking especialista';
        break; 
      }
     this.$api.post('auth/bitacora', {
          'correo' : '',
          'mrt' : '',
          'canal' : 'TODOS',
          'perfil' : 'Embajadores',
          'region' : 'N/A',
          'url' :  '/embajador',
          'seccion' : 'Reportes y ranking',
          'accion' : "Descarga",
          'detalle' :  seccion,
        }).then(
          response => {
             console.log(response);
          })
    },
    descargaReporte(){ 
        this.loader = true;
          this.$fileDownload(
                    this.$api.baseUri + 'auth/propio',
                    "reporte-ranking-propio.xlsx",
            ).then(
                () => {
                    this.loader = false;
                    this.apibitacora(1);
                }
            ) 
    },
    descargaReporte1(){ 
          this.loader = true;
            this.$fileDownload(
                      this.$api.baseUri + 'auth/retail',
                      "reporte-ranking-retail.xlsx",
              ).then(
                  () => {
                      this.loader = false;
                      this.apibitacora(2);
                  }
              ) 
    },
    descargaReporte2(){ 
            this.loader = true;
            this.$fileDownload(
                        this.$api.baseUri + 'auth/tlmkt',
                        "reporte-ranking-tlmkt.xlsx",
              ).then(
                  () => {
                      this.loader = false;
                      this.apibitacora(3);
                  }
              ) 
    },
     descargaReporte4(){ 
            this.loader = true;
            this.$fileDownload(
                        this.$api.baseUri + 'auth/especialista',
                        "reporte-ranking-especialista.xlsx",
              ).then(
                  () => {
                      this.loader = false;
                       this.apibitacora(4);
                  }
              ) 
    },
    cambiarTab(id){
      this.tab = id;   
    },
    ranki(){
      window.open('http://localhost:8080/ranking-embajador','_blank');
    },
    buscaGanadores(id){
        var url= 'auth/gana?canal='+ id;
            this.$api.get(url).then(
                ({data}) => {
                    console.log(data);
                    this.arrayGan = data.ganadores;      
                    this.canal = id;     
                    this.$confetti.start({
                          particles: [
                              {
                              type: 'rect',
                              },
                              {
                              type: 'circle',
                              },
                          ],
                          defaultColors: [
                              '#000',
                              '#ffffff',
                              '#187abe'
                          ],
                      });
                      setTimeout(() => {
                          this.$confetti.stop();
                      }, 4000);      
                }
            );
    },
    regresarHome(){
      this.$bus.$emit("cambia_fondo", true)
      location.reload();
    }
  },
}
</script>